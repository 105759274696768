import styled, { css } from 'styled-components';

import { get } from 'theme/utils/functions';
import breakpointsMedia from 'theme/utils/breakpointsMedia';

export const LiveOddsWrapper = styled.div`
  min-height: 250px;
  position: relative;

  ${breakpointsMedia({
    lg: css`
      min-height: 262px;
    `,
  })}

  ${({ theme }) => css`
    .head-to-head__wrapper {
      margin: ${get(theme, 'spacings.md')} 0;
    }

    .live-odds__slider-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      min-height: 250px;
      align-items: center;
      padding: ${get(theme, 'spacings.sm')};
      justify-content: space-between;
      border-radius: ${get(theme, 'borderRadius')};
      background-color: ${get(theme, 'colors.base.50')};

      &--empty {
        align-items: stretch;
      }

      ${breakpointsMedia({
        lg: css`
          min-height: 318px;
        `,
      })}

      h4 {
        align-self: flex-start;
      }
    }

    .live-odds__slider-item {
      width: 100%;
      min-height: 190px;
      padding: ${get(theme, 'spacings.sm')};
      border-radius: ${get(theme, 'borderRadius')};
      background-color: ${get(theme, 'colors.base.50')};

      ${breakpointsMedia({
        lg: css`
          width: auto;
          min-height: 256px;
        `,
      })}
    }

    .swiper {
      .swiper-pagination {
        display: none;
        height: 0.4rem;
        justify-content: center;
        gap: ${get(theme, 'spacings.xs')};
        bottom: ${get(theme, 'spacings.sm')};

        ${breakpointsMedia({
          lg: css`
            display: flex;
          `,
        })}

        .swiper-pagination-bullet {
          margin: 0;
          opacity: 1;
          width: 2.4rem;
          height: 0.4rem;
          border-radius: 0.2rem;
          background-color: ${get(theme, 'colors.base.200')};

          &.swiper-pagination-bullet-active {
            background-color: ${get(theme, 'colors.base.600')};
          }
        }
      }
    }

    .live-odds__skeleton {
      position: relative;
      align-items: normal;
      flex-direction: column;
      justify-content: flex-start;
      bottom: ${get(theme, 'spacings.sm')};

      span {
        width: 100%;
      }
    }

    &.hidden {
      visibility: hidden;
      height: 1px;
    }

    &.visible {
      visibility: visible;
      height: auto;
    }
  `}
`;

export const NumberPagination = styled.div`
  ${({ theme }) => css`
    position: absolute;
    border-radius: 1.6rem;
    top: ${get(theme, 'spacings.sm')};
    right: ${get(theme, 'spacings.sm')};
    padding: 0.2rem ${get(theme, 'spacings.sm')};
    border: 0.5px solid ${get(theme, 'colors.base.600')};
  `}
`;
